@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.deviceControlContainer {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  // border: 2px solid $soft-bg;
  padding: 15px;
  @include sm {
    border: none;
    padding: 0px 5px 0 0;
  }

  .led-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .led-indicator-on {
    background-color: green;
    box-shadow: 0 0 10px green;
  }

  .led-indicator-off {
    background-color: red;
    box-shadow: 0 0 10px red;
  }
  .leftContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    @include sm {
      display: none;
    }
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid $soft-bg;

    .firstRow {
      display: flex;
      flex-direction: row;
      padding: 15px;
      border-radius: 10px;
      border: 2px solid $soft-bg;
      margin-bottom: 15px;

      .titleContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        
        .pripojeni,
        .stav,
        .temp {
          flex: 1;
          padding: 10px;
        }
      }
    }

    .secondRow {
      display: flex;
      flex-direction: row;
      padding: 15px;
      border-radius: 10px;
      border: 2px solid $soft-bg;
      .titleContainer {
        display: flex;
        flex-direction: column;
        flex: 1;

        /* Stylování pro přepínač */
        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }

        /* "Slider" */
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        input:checked + .slider {
          background-color: #2196f3;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        /* Zaoblený slider */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
    }
  }
}
