.homeEmployeeContainer {
  width: 50vw;
  min-width: fit-content;
  margin-top: 20px;
  height: auto;
  padding: 20px;

  display: flex;
  flex-direction: column;
  user-select: none;

  .first {
    display: flex;
    flex-direction: row;
    user-select: none;
    justify-content: space-around;
    gap: 1%;
    .left-container {
      flex: 2;
      padding: 20px;
      background-color: #222b3c;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      user-select: none;

      .notesButton {
        display: flex;
        justify-content: right;
        margin-top: 10px;

        .saveButton {
          width: 100px;
          margin-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .mid-container {
      flex: 1;
      padding: 20px;
      background-color: #222b3c;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      user-select: none;
      height: 175px;
      .container {
        display: flex;
        justify-content: space-around;
        height: 100%;
        .inputSection {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 180px;
          margin-top: 10px;
          .ant-picker {
            width: 100%;
          }

          .saveButton {
            width: 100px;
            margin-top: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .second {
    display: flex;
    flex-direction: row;
    user-select: none;
    justify-content: space-around;
    .device {
      margin-top: 20px;
      margin-bottom: 20px;

      margin-right: 10px;
      width: 700px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    }

    .things {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
      width: 700px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
      height: auto;
    }
  }

  .third {
    display: flex;
    flex-direction: row;
    user-select: none;
    justify-content: center;

    .embded {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 10px;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

      iframe {
        margin: 20px 0px 20px 0px;
        border-radius: 8px;
        height: 700px;
        width: 1400px;
      }
    }
  }
}
